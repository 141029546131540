<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import { computed } from "vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import resourceFormPartialGate from "./resource-form-partial-gate.vue";
  import resourceFormFullGate from "./resource-form-full-gate.vue";
  import { useTranslation } from "i18next-vue";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import loaderDark from "../form-experience/loader-icon-dark.vue";
  import formLoadError from "./form-load-error.vue";
  import { GetComputedProperties, type CustomData } from "../../composables/use-custom-data-values.js";

  const LABEL_FOR_THIS_CALLER = "resource-form-custom-resource-base.vue";

  const props = defineProps<{
    formSettingsKey: "qx-demo-video";
    widgetData: FormWidgetData<"ContactForm">;
    customData?: CustomData;
    disableFormHeading?: boolean;
  }>();

  const { formHeading, disableFormHeading } = GetComputedProperties(props.customData);

  const resolvedDisableFormHeading = computed(() => {
    if(props.disableFormHeading) {
      return true;
    }
    if (disableFormHeading && disableFormHeading.value) {
      return true;
    }
    return false;
  });

  const { CurrentMarketoFormGatingState, FormState } = useFormServices({ callerLabel: LABEL_FOR_THIS_CALLER });

  const { t } = useTranslation();
  const formError = window.formLoadError;
</script>

<template>
  <template v-if="formError">
    <!-- If form does not load -->
    <formLoadError />
  </template>

  <template v-else-if="FormState === 'Loading' || FormState === 'Setup'">
    <!-- Loader Dark for the form -->
    <div class="form-loading">
      <loaderDark />
      <p> Loading Content</p>
    </div>
  </template>

  <template v-else>
    <template v-if="CurrentMarketoFormGatingState === 'PartialGate'">
      <resourceFormPartialGate v-bind="props">
        <template #heading>
          <slot name="heading">
            <template v-if="!resolvedDisableFormHeading">
              <h5 class="supporting-copy form-instructions-heading">
                {{ formHeading ? formHeading : t("resource-forms-Heading", { ns: TranslationNamespaceEnum.formHeadingText }) }}          
              </h5>
            </template>
          </slot>
        </template>
      </resourceFormPartialGate>
    </template>

    <template v-else-if="CurrentMarketoFormGatingState === 'FullGate'">
      <resourceFormFullGate v-bind="props">
        <template #heading>
          <slot name="heading">
            <template v-if="!resolvedDisableFormHeading">
              <h5 class="supporting-copy form-instructions-heading">
                {{ formHeading ? formHeading : t("resource-forms-Heading", { ns: TranslationNamespaceEnum.formHeadingText }) }}          
              </h5>
            </template> 
          </slot>
        </template>
      </resourceFormFullGate>
    </template>
  </template>
</template>
 